var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-container", { staticClass: "app-container Follow" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { margin: "0px 0px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticStyle: { margin: "20px 0 0" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { xs: 24, sm: 16, md: 8, lg: 7, xl: 4 },
                      },
                      [
                        _vm._v(
                          "\n                    承接项目：\n                    "
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "140px" },
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              size: "small",
                            },
                            model: {
                              value: _vm.projectType,
                              callback: function ($$v) {
                                _vm.projectType = $$v
                              },
                              expression: "projectType",
                            },
                          },
                          _vm._l(_vm.proOptions, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item, value: index },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { xs: 24, sm: 16, md: 8, lg: 6, xl: 4 },
                      },
                      [
                        _vm._v(
                          "\n                    状态：\n                    "
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "140px" },
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              size: "small",
                            },
                            model: {
                              value: _vm.status,
                              callback: function ($$v) {
                                _vm.status = $$v
                              },
                              expression: "status",
                            },
                          },
                          _vm._l(_vm.statusOptions, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item, value: index },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { xs: 24, sm: 16, md: 8, lg: 9, xl: 5 },
                      },
                      [
                        _vm._v(
                          "\n                    添加时间：\n                    "
                        ),
                        _c("el-date-picker", {
                          staticStyle: { width: "220px" },
                          attrs: {
                            type: "daterange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "value-format": "timestamp",
                            size: "small",
                            clearable: "",
                            "default-time": ["00:00:00", "23:59:59"],
                          },
                          on: { change: _vm.DataTime },
                          model: {
                            value: _vm.createTime,
                            callback: function ($$v) {
                              _vm.createTime = $$v
                            },
                            expression: "createTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 4, lg: 5, xl: 3 } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入城市名称",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.cityName,
                            callback: function ($$v) {
                              _vm.cityName = $$v
                            },
                            expression: "cityName",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 4, lg: 2, xl: 1 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handleFilter },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 4, lg: 2, xl: 1 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit("add", "")
                              },
                            },
                          },
                          [_vm._v("新增")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "tb",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        "tooltip-effect": "dark",
                        border: "",
                        "header-cell-style": {
                          background: "#F9F9F9",
                          color: "#222222",
                          textAlign: "center",
                        },
                        "cell-style": { textAlign: "center" },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { label: "序号", width: "60" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        scope.$index +
                                          (_vm.query.page - 1) *
                                            _vm.query.pagesize +
                                          1
                                      ) + " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3093164208
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "城市名称",
                          prop: "city_name",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "承接项目",
                          prop: "project_type",
                          formatter: _vm.formatProject,
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "对应组织架构",
                          prop: "structure_names",
                          formatter: _vm.formatBumen,
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "状态", prop: "status" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.status == 1
                                    ? _c("span", { staticClass: "success" }, [
                                        _vm._v("启用"),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == 2
                                    ? _c("span", { staticClass: "error" }, [
                                        _vm._v("停用"),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3513341595
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "添加时间",
                          prop: "create_time",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "center",
                          width: "180px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEdit(
                                            "edit",
                                            scope.row.id
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _vm._v(" "),
                                  scope.row.status == 1
                                    ? _c(
                                        "el-popconfirm",
                                        {
                                          attrs: {
                                            title: "确定停用该区域业务？",
                                            icon: "el-icon-warning",
                                          },
                                          on: {
                                            onConfirm: function ($event) {
                                              return _vm.changeStatus(
                                                scope.row.id,
                                                2
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                slot: "reference",
                                                type: "danger",
                                                size: "mini",
                                              },
                                              slot: "reference",
                                            },
                                            [_vm._v("停用")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  scope.row.status == 2
                                    ? _c(
                                        "el-popconfirm",
                                        {
                                          attrs: {
                                            title: "确定启用该区域业务？",
                                            icon: "el-icon-warning",
                                          },
                                          on: {
                                            onConfirm: function ($event) {
                                              return _vm.changeStatus(
                                                scope.row.id,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                slot: "reference",
                                                type: "success",
                                                size: "mini",
                                              },
                                              slot: "reference",
                                            },
                                            [_vm._v("启用")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2400757162
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                _vm._v(" "),
                _c(
                  "el-col",
                  {
                    staticClass: "toolbar",
                    staticStyle: { margin: "10px 0px" },
                    attrs: { span: 24 },
                  },
                  [
                    _c("pagination", {
                      attrs: {
                        "current-page": _vm.query.page,
                        totalPage: _vm.query.total,
                        pageSize: _vm.query.pagesize,
                      },
                      on: {
                        handleSizeChange: _vm.handleSizeChange,
                        handleCurrentChange: _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    staticStyle: { margin: "0 auto" },
                    attrs: {
                      title: _vm.vals,
                      visible: _vm.dialogCity,
                      width: "600px",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogCity = $event
                      },
                      close: _vm.cancel,
                    },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "ruleForm",
                        staticClass: "demo-ruleForm",
                        attrs: {
                          model: _vm.ruleForm,
                          rules: _vm.rules,
                          "label-width": "120px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "城市名称", prop: "city" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择城市名称",
                                  size: "small",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.ruleForm.city,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "city", $$v)
                                  },
                                  expression: "ruleForm.city",
                                },
                              },
                              _vm._l(_vm.cityOption, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item, value: index },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "承接项目", prop: "project" } },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                model: {
                                  value: _vm.ruleForm.project,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "project", $$v)
                                  },
                                  expression: "ruleForm.project",
                                },
                              },
                              _vm._l(_vm.projects, function (item, index) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: index,
                                    attrs: {
                                      label: item.value,
                                      name: "project",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "对应组织架构", prop: "structure" },
                          },
                          [
                            _c(
                              "el-checkbox-group",
                              {
                                staticClass: "structure",
                                model: {
                                  value: _vm.ruleForm.structure,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "structure", $$v)
                                  },
                                  expression: "ruleForm.structure",
                                },
                              },
                              _vm._l(_vm.bumenOption, function (item, index) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: index,
                                    attrs: {
                                      label: item.id,
                                      name: "structure",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: { "text-align": "right" },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                _vm.dialogCity = false
                              },
                            },
                          },
                          [_vm._v("取消")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading.fullscreen.lock",
                                value: _vm.fullscreenLoading,
                                expression: "fullscreenLoading",
                                modifiers: { fullscreen: true, lock: true },
                              },
                            ],
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.createData("ruleForm")
                              },
                            },
                          },
                          [_vm._v("确认")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }