import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { PromoteCityList, PromoteCityStatus, PromoteCityAdd, PromoteCityRead, PromoteCityUpdate, getAllProvince as _getAllProvince, getOptionalStructure } from '@/api/updateUserInfo/notice';
export default {
  data: function data() {
    return {
      //系统教程
      tab: 0,
      loading: true,
      course: this.$route.query.course,
      //系统教程
      radio: 1,
      title: '展业城市配置',
      value: '',
      tableData: [],
      start_time: '',
      end_time: '',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      projectType: '0',
      createTime: '',
      status: '0',
      cityName: '',
      proOptions: {
        0: '全部',
        1: '可认证',
        2: '不可认证',
        3: '社科高级'
      },
      statusOptions: {
        0: '全部',
        1: '启用',
        2: '停用'
      },
      projects: [{
        value: 1,
        name: '可认证'
      }, {
        value: 2,
        name: '不可认证'
      }, {
        value: 3,
        name: '社科高级'
      }],
      cityOption: {},
      bumenOption: [],
      ruleForm: {
        id: '',
        city: '',
        project: [],
        structure: []
      },
      rules: {
        city: [{
          required: true,
          message: '请选择城市',
          trigger: 'change'
        }],
        project: [{
          type: 'array',
          required: true,
          message: '请至少选择一个承接项目',
          trigger: 'change'
        }],
        structure: [{
          type: 'array',
          required: true,
          message: '请至少选择一个组织架构',
          trigger: 'change'
        }]
      },
      vals: '',
      dialogCity: false,
      fullscreenLoading: false
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.GetList(this.query.page, this.query.pagesize);
      this.getAllProvince();
    } else {}
  },
  computed: {},
  components: {
    Head: Head
  },
  methods: {
    getTimestamp: function getTimestamp(time) {
      //把时间日期转成时间戳
      return new Date(time).getTime() / 1000;
    },
    getAllProvince: function getAllProvince() {
      var _this = this;
      _getAllProvince().then(function (respomse) {
        _this.cityOption = respomse.data;
      });
    },
    GetList: function GetList(page, pagesize) {
      var _this2 = this;
      this.loading = true;
      PromoteCityList({
        page: String(page),
        pagesize: String(pagesize),
        search_project_type: Number(this.projectType),
        search_status: Number(this.status),
        search_start_time: this.start_time,
        search_end_time: this.end_time,
        search_city_name: this.cityName
      }).then(function (respomse) {
        _this2.loading = false;
        _this2.tableData = respomse.data.data;
        _this2.query.total = respomse.data.total;
      });
    },
    formatBumen: function formatBumen(row) {
      if (row.structure_names) {
        var arr = [];
        row.structure_names.forEach(function (item) {
          arr.push(item);
        });
        return arr.join('，');
      }
    },
    formatProject: function formatProject(row) {
      if (row.project_type_name) {
        var arr = [];
        row.project_type_name.forEach(function (item) {
          arr.push(item);
        });
        return arr.join('，');
      }
    },
    formatStatus: function formatStatus(row) {
      if (row.status == 1) {
        return '启用';
      } else if (row.status == 2) {
        return '停用';
      } else {
        return '-';
      }
    },
    //时间戳查询
    DataTime: function DataTime() {
      this.NumberEs = Number(0);
      this.query.page = 1;
      if (this.createTime == null) {
        this.start_time = '';
        this.end_time = '';
      } else {
        var s_time = this.createTime[0].toString();
        this.start_time = s_time.substring(0, 10);
        var e_time = this.createTime[1].toString();
        this.end_time = e_time.substring(0, 10);
      }
    },
    //每页最多显示条数10   20   30   50
    handleSizeChange: function handleSizeChange(val) {
      var _this3 = this;
      this.query.pagesize = val;
      this.$nextTick(function () {
        _this3.GetList(_this3.query.page, _this3.query.pagesize);
      });
    },
    //翻页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this4 = this;
      this.query.page = val;
      this.$nextTick(function () {
        _this4.GetList(_this4.query.page, _this4.query.pagesize);
      });
    },
    //查询
    handleFilter: function handleFilter() {
      var _this5 = this;
      this.query.page = 1;
      this.$nextTick(function () {
        _this5.GetList(_this5.query.page, _this5.query.pagesize);
      });
    },
    confirm: function confirm() {
      this.$emit('onConfirm');
    },
    changeStatus: function changeStatus(id, status) {
      var _this6 = this;
      PromoteCityStatus({
        id: id,
        status: status
      }).then(function (respomse) {
        _this6.$notify({
          title: '成功',
          message: '操作成功',
          type: 'success',
          duration: 2000
        });
        _this6.query.page = 1;
        _this6.GetList(_this6.query.page, _this6.query.pagesize);
      });
    },
    handleEdit: function handleEdit(val, key) {
      var _this7 = this;
      //  新增/新增子栏目/编辑弹窗
      if (val == 'edit') {
        this.vals = '编辑展业城市';
        this.dialogCity = true;
        getOptionalStructure({
          id: key
        }).then(function (respomse) {
          _this7.bumenOption = respomse.data;
        });
        PromoteCityRead({
          id: String(key)
        }).then(function (respomse) {
          _this7.ruleForm.id = respomse.data.id;
          _this7.ruleForm.city = respomse.data.city_id.toString();
          _this7.ruleForm.project = respomse.data.project_type.split(',').map(function (res) {
            return res * 1;
          });
          _this7.ruleForm.structure = respomse.data.structure_ids.split(',').map(function (res) {
            return res * 1;
          });
        });
      } else if (val == 'add') {
        this.vals = '新增展业城市';
        this.dialogCity = true;
        getOptionalStructure().then(function (respomse) {
          _this7.bumenOption = respomse.data;
        });
      }
    },
    createData: function createData(formName) {
      var _this8 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this8.vals == '新增展业城市') {
            PromoteCityAdd({
              city_id: Number(_this8.ruleForm.city),
              project_type: _this8.ruleForm.project.toString(),
              structure_ids: _this8.ruleForm.structure.toString()
            }).then(function (respomse) {
              _this8.$notify({
                title: '成功',
                message: '新增成功',
                type: 'success',
                duration: 2000
              });
              _this8.dialogCity = false;
              _this8.$refs.ruleForm.resetFields();
              _this8.query.page = 1;
              _this8.GetList(_this8.query.page, _this8.query.pagesize);
            });
          } else if (_this8.vals == '编辑展业城市') {
            PromoteCityUpdate({
              id: Number(_this8.ruleForm.id),
              city_id: Number(_this8.ruleForm.city),
              project_type: _this8.ruleForm.project.toString(),
              structure_ids: _this8.ruleForm.structure.toString()
            }).then(function (respomse) {
              _this8.$notify({
                title: '成功',
                message: '编辑成功',
                type: 'success',
                duration: 2000
              });
              _this8.dialogCity = false;
              _this8.$refs.ruleForm.resetFields();
              _this8.query.page = 1;
              _this8.GetList(_this8.query.page, _this8.query.pagesize);
            });
          }
        } else {
          return false;
        }
      });
    },
    cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.ruleForm.resetFields();
      this.bumenLabel = [];
    }
  }
};